<template>
  <figure>
    <RokkaImage
      v-if="imageStyle && rokkaImageHash"
      :config="imageStyle"
      :file-name="rokkaFileName"
      :alt="image?.alt"
      :title="image?.title"
      :img-class="imgClass"
      :loading="loading"
      :preload="preload"
      :hash="rokkaImageHash"
    />
    <img v-else :src="imgSrc" :loading="loading" :class="imgClass" />
  </figure>
</template>

<script lang="ts" setup>
import type { DefineImageStyleConfig } from '#rokka/types'
import type { MediaImageFragment } from '#graphql-operations'
import { rokkaConfig } from '~/nuxtConfig/rokka'

type BaseProps = {
  imgClass?: string
  eager?: boolean
}

type RokkaBaseProps = {
  imageStyle?: DefineImageStyleConfig

  /**
   * Preload the image. When set, the images are reploaded using a <link> tag.
   */
  preload?: boolean
}

type RokkaWithHashProps = RokkaBaseProps & {
  rokkaHash?: string
}

type RokkaWithImageProps = RokkaBaseProps & {
  image?: MediaImageFragment['image']
}

type ImgProps = {
  url?: MediaImageFragment['url'] | string
}

type RokkaProps = RokkaBaseProps &
  RokkaWithHashProps &
  RokkaWithImageProps &
  ImgProps

export type MediaImageProps = BaseProps & RokkaProps

const props = defineProps<MediaImageProps>()

defineOptions({
  name: 'MediaImage',
})

const actualUrl = computed(() => {
  if ('url' in props) {
    if (typeof props.url === 'string') {
      return props.url
    } else {
      return props.url?.path
    }
  }

  return undefined
})
const actualUrlParsed = computed(() => {
  // need try catch here since URL() throws an error if we get a relative path (which we get in Blökkli when the image comes from Drupal and not from rokka)
  try {
    if (actualUrl.value) {
      return new URL(actualUrl.value)
    }
  } catch {}

  return undefined
})

const loading = computed(() => (props.eager ? 'eager' : 'lazy'))

// Ensure the file ending is .jpg so rokka is allowed to deliver lossy versions.
// Best would be to have no file ending since rokka anyway does not return what we ask for,
// but with no file ending we get a 404.
// We have autoformat enabled on all our rokka stacks for the frontend.
const ensureJpg = (img?: string) => {
  const pathArray = img?.split('/') ?? []
  const oldFilename = pathArray.pop()

  if (!oldFilename) {
    return
  }

  const withoutEnding = oldFilename.replace(/(-v[0-9]+)?\.[^.]+$/, '')

  // if no version was specified. Add -v1 so new (smaller because of autoformat) images are delivered.
  let newFilename = withoutEnding
  if (!withoutEnding.match(/-v[0-9]+$/)) {
    newFilename += '-v1'
  }
  newFilename += '.jpg'

  const path = pathArray.join('/')
  return (path.length > 0 ? path + '/' : '') + newFilename
}

const imgSrc = computed(() => {
  if (rokkaImageHash.value) {
    // Use the 'dynamic' stack for images where we only provide the hash and no image style.
    // Our stacks 'fe_vuepal_crop' and 'fe_vuepal_no_crop' both need a provided with. And we
    // do not know the with of the image or which width we need here if no image style is provided.
    const rokkaUrl = buildRokkaUrl(
      'dynamic', // stack
      undefined, // dpr
      rokkaImageHash.value, // hash
      rokkaFileName.value, // name
    )
      .replace(/([^:])\/\//g, '$1/')
      .replace('/dynamic/', '/dynamic/options-autoformat-true/')

    return ensureJpg(rokkaUrl)
  }

  // only ensure jpg if we are on rokka and autoformat is true.
  if (
    actualUrlParsed.value &&
    actualUrlParsed.value.hostname.match(/rokka.io$/) &&
    (actualUrlParsed.value.pathname.includes(rokkaConfig.stacks.crop) ||
      actualUrlParsed.value.pathname.includes(rokkaConfig.stacks.noCrop) ||
      actualUrlParsed.value.pathname.includes('autoformat-true'))
  ) {
    return ensureJpg(actualUrl.value)
  } else {
    return actualUrl.value
  }
})

const rokkaImageHash = computed(() => {
  let rokkaImageHash

  if (props.rokkaHash) {
    rokkaImageHash = props.rokkaHash
  } else if (props.image) {
    rokkaImageHash = props.image.file?.rokka?.hash
  } else if (
    actualUrlParsed.value &&
    actualUrlParsed.value.hostname === 'image.oris.ch'
  ) {
    // this url is whitelisted at rokka to proxy urls from.
    rokkaImageHash = `-${actualUrl.value}-`
  }

  return rokkaImageHash
})

const rokkaFileName = computed(() => {
  let rokkaFileName = props.image?.file?.label
  if (!rokkaFileName && actualUrl.value) {
    rokkaFileName = actualUrl.value.split('/').pop()
  }

  return ensureJpg(rokkaFileName ?? 'image.jpg')
})
</script>
