import validate from "/builds/oris/oris-web/frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import prefix_45redirect_45global from "/builds/oris/oris-web/frontend/middleware/prefixRedirect.global.ts";
import restrict_45styleguide_45global from "/builds/oris/oris-web/frontend/middleware/restrictStyleguide.global.ts";
import manifest_45route_45rule from "/builds/oris/oris-web/frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  prefix_45redirect_45global,
  restrict_45styleguide_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}