<template>
  <Swiper
    :modules="actualModules"
    :mousewheel="{ releaseOnEdges: true, forceToAxis: true }"
    :scrollbar="scrollbar"
    class="text-nowrap"
    @slide-change="onSlideChange"
  >
    <slot></slot>
  </Swiper>
</template>

<script lang="ts" setup>
import { Swiper } from 'swiper/vue'
import { Mousewheel } from 'swiper/modules'
import type { ScrollbarOptions } from 'swiper/types'

const props = defineProps<{
  modules?: unknown[] | undefined
  scrollbarMarginTop?: number
  scrollbar?: ScrollbarOptions | boolean
}>()

const actualModules = computed(() => {
  return props.modules?.concat([Mousewheel])
})

const scrollbarMarginTopPx = computed(() =>
  props.scrollbarMarginTop ? `${props.scrollbarMarginTop ?? 32}px` : '',
)

const emit = defineEmits<{
  slideChanged: []
}>()

const onSlideChange = () => {
  emit('slideChanged')
}
</script>

<style lang="scss" scoped>
:deep() {
  .swiper-wrapper {
    height: auto;
  }

  .swiper-slide {
    width: auto;
    display: inline-block;
  }

  .swiper-scrollbar {
    @apply w-full bg-grey-light-01;

    margin-top: v-bind('scrollbarMarginTopPx');

    border-radius: 0;
    height: 2px;

    .swiper-scrollbar-drag {
      @apply bg-black;

      border-radius: 0;
      height: 100%;
    }
  }
}
</style>
